.App {
    font-family: 'Roboto';
    /* display: flex; */
    /* flex-direction: column; */
    /* min-height: 100vh; */
    /* margin: 0px; */
}
.Web {
    display: flex;
    min-height: 100vh;
    margin: 0px;
}
h1 {
    font-size: 24px;
    font-weight: 300;
    line-height: 16px;
    font-family: 'Roboto Slab'
}

h2 {
    font-size: 18px;
    font-weight: 300;
    line-height: 16px;
}

p {
    font-size: 14px;
    line-height: 18px;
}

a {
    text-decoration: none;
    color: black;
}

a:hover {
    color: dodgerblue;
}

/* Containers */

.home {
    height: 40vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    position: relative;
    transition: flex 0.4s ease-in-out;
    white-space: nowrap;
}

.portfolio {
    display: flex;
    flex-direction: column;
    flex: 3;
}

/* Sliders */

.phoneRow > div{
    height: 20vh;
}

.projectWrapper {
    height: 30vh;
    width: 100%;
    overflow: hidden;
}
.slideReel {
    position: relative;
    display: flex;
    flex-direction: row;
    width: 200%;
    height: inherit;
    cursor: pointer;
    transition: left 400ms ease-in-out;
}
.infoHidden {
    left: 0%;
}
.infoVisible {
    left: -100%;
}
.previewWrapper {
    height: inherit;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
}
video {
    border-radius: 3px;
    box-shadow:0px 0px 10px rgba(0, 0, 0, 0.8);
}
.loader {
    width: 100%;
    text-align: center;
    font-size: 70px;
    animation-duration: 2s;
    animation-name: spin;
    font-family: 'Roboto Slab';
    animation-iteration-count: infinite;
    opacity: 1;
    transition: opacity 1;
}
.loader.hide {
    display: none;
    opacity: 0;
}  
.videoPreview.isLoading {
    display: none;
}
.videoPreview {
    animation-duration: 0.5s;
    animation-name: fadeIn;
}
.imagePreview {
    height: 100%;
    width: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50% 50%;
}
.projectPreview, .projectPreview > div{
    display: flex;
    flex: 1;
    color: white;
    height: inherit;
    overflow: hidden;
    width: auto;
    transition: flex 400ms ease-in-out;
    white-space: nowrap;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.2);
}

.projectInfo, .projectInfo > div{
    display: flex;
    flex: 1;
    color: black;
    height: inherit;
    overflow: hidden;
    width: 100%;
    transition: flex 400ms ease-in-out;
    white-space: nowrap;
    box-shadow: inset 0px 0px 5px rgba(0, 0, 0, 0.2);
}

.info {
    flex: 1;
    background-color: white;
    overflow: hidden;
    white-space: nowrap;
}

.hide-desk {
    display: block;
}

.hide-mobile {
    display: none;
}

/* Previews */

.mob-video {
    width: 100%;
    background-color: white;
    display: flex;
    justify-content: center;
    position: relative;
    height: 300px;
    overflow: hidden;
}

.mob-video video {
    object-fit: cover;
    height: inherit;
    position: absolute;
}

/* Info */

.x-mark-container {
    justify-content: flex-end;
}

.x-mark {
    transition: transform 0.2s ease-in-out;
    cursor: pointer;
}

.x-mark:hover {
    transform: rotate(-90deg);
}

.app-name {
    font-size: 20px;
    color: white;
    font-family: 'Roboto'
}

.app-title {
    font-size: 20px;
    padding: 16px 0px;
    font-weight: 300;
    font-family: 'Roboto Slab'
}
.app-title a {
    font-size: 12px;
    padding-left: 10px;
    text-decoration: none;
}
.app-title a:hover {
    text-decoration: underline;
}

.tech-details {
    font-size: 16px;
    font-family: 'Roboto Slab';
    padding: 8px 0px 8px;
}

.info-inner {
    width: 80%;
    text-align: left;
    margin: 0 auto;
    line-height: 18px;
    overflow: hidden;
}

.info-container {
    width: 100%;
    margin: 0px;
    height: 100%;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
}

.info-inner p {
    margin: 0px;
}

.info-title-container {
    display: flex;
    justify-content: center;
    flex-direction: row;
    overflow: hidden;
}

.info-title-container div {
    flex: 1;
    display: flex;
    align-items: center;
}

hr {
    border: 1px solid black;
    width: 90%;
    margin-left: 0;
}

/* Project Specific */

.paintByCountyWrapper hr {
    border: 1px solid #041045;
}
.paintByCountyPreview {
    background:#ffe09e
}
.paintByCountyPreview video {
    filter: brightness(1.75);
}
.memeCasedWrapper hr {
    border: 1px solid black;
}
.memeCasedPreview {
    background: #f76053;
}
.memeCasedPreview .previewWrapper {
    width: 85%;
    margin: 0 auto;
}
.plusItWrapper hr {
    border: 1px solid #FCAB41;
}
.plusItPreview {
    background: rgb(252, 171, 65);
    height: inherit;
}
.accutypeWrapper hr {
    border: 1px solid #5C9BF2;
}
.accutypePreview {
    background: rgb(92, 155, 242);
    height: inherit;
}
.thickitWrapper hr {
    border: 1px solid #5FAC5F;
}
.thickitPreview {
    background: #5FAC5F;
    height: inherit;
}

/* Desktop */
@media only screen and (min-width: 950px) {
    .App {
        height: 100vh;
        width: 100%;
        margin: 0;
        flex-direction: row;
    }
    .slideReel{
        min-height: 40vh;
    }
    .projectWrapper{
        height: auto;
    }
    .hide-desk {
        display: none;
    }
    .hide-mobile {
        display: flex;
    }
    .home{
        text-align: center;
        position: static;
        height: inherit;
        width: 25%;
    }
    .webRow, .phoneRow{
        display: flex;
        flex-direction: row;
    }
    .webRow {
        height: 40vh;
    }
    .phoneRow > div{
        height: 60vh;
    }
    .imagePreview {
        height: 90%;
        width: 90%;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: 50% 50%;
    }
    video {
        margin: 0 auto;
        cursor: pointer;
        position: relative;
    }
    ul {
        list-style: none;
        margin-top: 5px;
        padding-left: 0px;
    }
    .phoneRow video {
        width: 60%;
    }
    .webRow video {
        width: 90%;
    }
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
  
    to {
        transform: rotate(360deg);
    }
  }
  @-moz-keyframes spin {
    from {
        transform: rotate(0deg);
    }
  
    to {
        transform: rotate(360deg);
    }
  }

  @-webkit-keyframes fadeIn {
    from {
        transform: rotate(0deg);
    }
  
    to {
        transform: rotate(360deg);
    }
  }
  
  @-o-keyframes fadeIn {
    from {
        transform: rotate(0deg);
    }
  
    to {
        transform: rotate(360deg);
    }
  }
  
  @-ms-keyframes fadeIn {
    from {
        transform: rotate(0deg);
    }
  
    to {
        transform: rotate(360deg);
    }
  }

  @keyframes fadeIn {
    from {
      opacity:0;
    }
    to {
      opacity:1;
    }
  }
  
  @-moz-keyframes fadeIn {
    from {
      opacity:0;
    }
    to {
      opacity:1;
    }
  }
  
  @-webkit-keyframes fadeIn {
    from {
      opacity:0;
    }
    to {
      opacity:1;
    }
  }
  
  @-o-keyframes fadeIn {
    from {
      opacity:0;
    }
    to {
      opacity:1;
    }
  }
  
  @-ms-keyframes fadeIn {
    from {
      opacity:0;
    }
    to {
      opacity:1;
  }
}